import styled from 'styled-components';
import LSTVButton from '~/components/LSTVButton';
import LSTVLink from '~/components/LSTVLink';
import { BookmarkButton } from '../BookmarkButton';

export const Container = styled(LSTVLink)`
  min-width: 0;
  text-decoration: none;
  color: inherit !important;
`;

export const NoLinkContainer = styled.div`
  min-width: 0;
  text-decoration: none;
  color: inherit !important;
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  & > .carousel-root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .carousel-root,
  .carousel-slider,
  .slider-wrapper,
  .slider {
    height: 100%;
  }

  &:not(:hover) .hover {
    display: none;
  }

  &:hover .no-hover {
    display: none;
  }
`;

export const WfaBadge = styled.img`
  position: absolute;
  left: 14px;
  top: 14px;
  width: 14.5%;
  background-color: ${(props) => props.theme.highlight_1_0};
  border-radius: 1000px;
`;

export const StyledBookmarkButton = styled(BookmarkButton)`
  position: absolute;
  right: 0;
  top: 0;
  width: 56px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${(props) => props.theme.white};
  cursor: pointer;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
`;

export const CaptionContainer = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.coldGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
`;

export const NameContainer = styled.div`
  flex: 1 1 0;
  font-size: 19px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 9px;
`;

export const SuggestedContainer = styled.div`
  font-size: 17px;
  font-weight: 500;
`;

export const SendMessageButton = styled(LSTVButton)`
  font-size: 15px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.coldGrey};
  border-radius: 8px;
  margin-top: 2px;
  flex: 1 0 162px;
`;

/**
 * We use `pseudoDisabled` instead of real `disabled` because we want to
 * preventDefault/stopPropagation on clicks even when there are no more images
 * available to slide to, so that the enclosing link doesn't get clicked.
 */
export const NextOrPreviousButton = styled(LSTVButton)<{ $isNext: boolean; $pseudoDisabled: boolean }>`
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  background-color: ${(props) => props.theme.white};
  font-size: 10px;
  position: absolute;
  top: calc(50% - 14px);
  ${(props) => (props.$isNext ? 'right' : 'left')}: 21px;
  border-radius: 28px;
  // To better position the arrow.
  ${(props) => (props.$isNext ? 'padding-left' : 'padding-right')}: 2px;
  z-index: 2;
  ${(props) => (props.$pseudoDisabled ? 'opacity: 0.5;' : '')}
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

export const IndexIndicator = styled(LSTVButton)<{ selected: boolean }>`
  height: 7px;
  width: 7px;
  cursor: pointer;
  background-color: ${(props) => props.theme.white};
  border-radius: 7px;
  margin: 0 3px;
  ${(props) => (!props.selected ? 'opacity: 0.5;' : '')}
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;
