import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle as faCheckCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBusinessAward } from '@lstv/core/types';
import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Avatar from '~/components/Avatar';
import LSTVImage from '~/components/LSTVImage';
import { EventParams } from '~/globals/trackEvent';
import { useModals } from '~/hooks/useModals';
import * as S from './index.styles';
import Image from 'next/image'

const preventEnclosingLinkClick = (event: React.MouseEvent<Element, MouseEvent>) => {
  // Prevent navigation via enclosing link.
  event.preventDefault();
  // Prevent click-link event from being generated.
  event.stopPropagation();
};

enum DisplayMode {
  OnHover,
  Never,
  Always,
}

const renderNextOrPreviousButton = (
  isNext: boolean,
  onClick: () => void,
  moreItemsExist: boolean,
  displayMode: DisplayMode
) =>
  displayMode !== DisplayMode.Never && (
    <S.NextOrPreviousButton
      className={displayMode === DisplayMode.OnHover ? 'hover' : undefined}
      {...{ $isNext: isNext }}
      $pseudoDisabled={!moreItemsExist}
      aria-label={isNext ? 'Next thumbnail' : 'Previous thumbnail'}
      onClick={(event) => {
        preventEnclosingLinkClick(event);
        onClick();
      }}
    >
      <FontAwesomeIcon icon={isNext ? faChevronRight : faChevronLeft} size="lg" />
    </S.NextOrPreviousButton>
  );

const touchOnlyDevice = typeof window !== 'undefined' && window.matchMedia('(hover: none)').matches;

/**
 * A global used to make sure that when a card is activated by tapping it on
 * mobile, any previously activated card gets disactivated.
 */
let onCardClick: () => void | undefined = undefined;

const VendorCard = ({
  slug,
  name,
  location,
  thumbnailUrl,
  premium,
  thumbnailAlt,
  roles,
  avatarUrl,
  business,
  extraThumbnailUrls,
  awards,
  weddingTeamContactUsUrl,
  blankTarget,
  noSendMessage
}: {
  slug: string;
  name: string;
  location?: string | null;
  thumbnailUrl: string;
  premium?: boolean;
  blankTarget?: boolean;
  thumbnailAlt?: string;
  roles: { name: string }[];
  avatarUrl?: string;
  noSendMessage?: boolean;
  /**
   * This is a business object passed via useModals to ContactBusinessForm.
   */
  business: any;
  extraThumbnailUrls?: string[];
  awards?: IBusinessAward[];
  weddingTeamContactUsUrl?: string;
}) => {
  const { openContactBusinessModal } = useModals();
  const [active, setActive] = React.useState(false);
  const displayMode = touchOnlyDevice ? (active ? DisplayMode.Always : DisplayMode.Never) : DisplayMode.OnHover;

  useEffect(() => {
    if (active) {
      onCardClick = () => setActive(false);
      return () => {
        onCardClick = undefined;
      };
    }
  }, [active]);

  const handleSendMessage = (event) => {
    preventEnclosingLinkClick(event);

    if (weddingTeamContactUsUrl) {
      window.open(weddingTeamContactUsUrl, '_blank');
    } else {
      openContactBusinessModal({ business });
    }
  };

  return (
    <EventParams subsection="vendor_card" subsection_slug={slug}>
      <S.Container
        to={`/business/${slug}`}
        blankTarget = {blankTarget || undefined}
        onClick={(event) => {
          if (touchOnlyDevice && !active) {
            onCardClick?.();
            setActive(true);
            event.preventDefault();
          }
        }}
        style={{ minWidth: '0' }}
      >
        <S.ThumbnailContainer>
          <Carousel
            // workaround for https://github.com/leandrowd/react-responsive-carousel/issues/621
            interval={9999999}
            showStatus={false}
            showThumbs={false}
            showIndicators={touchOnlyDevice}
            renderArrowPrev={(onClick, moreItemsExist) =>
              renderNextOrPreviousButton(false, onClick, moreItemsExist, displayMode)
            }
            renderArrowNext={(onClick, moreItemsExist) =>
              renderNextOrPreviousButton(true, onClick, moreItemsExist, displayMode)
            }
            renderIndicator={(onClick, selected, index, label) => (
              <S.IndexIndicator
                {...{ selected }}
                onClick={(event) => {
                  preventEnclosingLinkClick(event);
                  onClick(event);
                }}
                aria-label={label}
              />
            )}
          >
            {[thumbnailUrl, ...(extraThumbnailUrls ?? [])]
              // dedupe
              .reduce((acc, value) => [...acc, ...(acc.includes(value) ? [] : [value])], [])
              .map((url) => (
                // <Image
                //   key={url}
                //   src={url}
                //   alt={thumbnailAlt}
                //   layout="fill"
                //   objectFit="cover"
                //   priority={true}
                //   />
                <LSTVImage key={url} url={url} alt={thumbnailAlt} />
              ))}
          </Carousel>
          {awards && awards.length > 0 && <S.WfaBadge src={awards[0].award_image} />}
          {displayMode !== DisplayMode.Never && (
            <S.StyledBookmarkButton
              className={displayMode === DisplayMode.OnHover ? 'hover' : undefined}
              type="business"
              slug={slug}
            />
          )}
        </S.ThumbnailContainer>
        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 9px' }}>
          <Avatar
            imageSrc={avatarUrl}
            initial={name.slice(0, 1)}
            size="10%"
            minSize="30px"
            maxSize="39px"
            fontSize="18px"
          />
          <S.NameContainer>{name}</S.NameContainer>
          {premium && (
            <>
              <FontAwesomeIcon style={{ fontSize: '20px', marginRight: '4px' }} icon={faCheckCircleSolid} size="lg" />
              <S.SuggestedContainer>Suggested</S.SuggestedContainer>
            </>
          )}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '9px' }}>
          <div style={{ flex: '100 1 240px', overflow: 'hidden', marginRight: '4px', marginBottom: '2px' }}>
            <S.CaptionContainer>
              {roles.map(({ name }) => name).reduce((acc, value) => `${acc} · ${value}`) || <>&nbsp;</>}
            </S.CaptionContainer>
            <S.CaptionContainer>{location || <>&nbsp;</>}</S.CaptionContainer>
          </div>
          {!noSendMessage && (<S.SendMessageButton eventAction="Send Message" onClick={handleSendMessage}>
            Send Message
          </S.SendMessageButton>)}
        </div>
      </S.Container>
    </EventParams>
  );
};

export default VendorCard;
